import React, { useEffect, useState } from 'react';
import './Quiz.css'; // Import the CSS file

const Quiz = ({ questions, onFinish, onAnswer }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [isFinishing, setIsFinishing] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showPhoneError, setShowPhoneError] = useState(false);

  const handleAnswerSelect = (answer) => {
    setSelectedAnswer(answer);
    setAnswers([...answers, { question: questions[currentQuestionIndex].question, answer }]);
    onAnswer(); // Call the onAnswer callback to update the progress bar
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setSelectedAnswer(null);
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        setIsFinished(true);
      }
    }, 1000); // 1 second delay
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    if (e.target.value.trim()) {
      setShowPhoneError(false);
    }
  };

  const handleFinish = async () => {
    if (phoneNumber.trim() === '') {
      setShowPhoneError(true);
      return;
    }
    localStorage.setItem('quizPhoneNumber', phoneNumber);
    setIsFinishing(true);
    await onFinish(answers); // Assuming onFinish is a promise-based function
    setIsFinishing(false);
    // Reset the state after finishing
    setIsFinished(false);
    setCurrentQuestionIndex(0);
    setAnswers([]);
    setPhoneNumber('');
  };

  useEffect(() => {
    if (isFinished) {
      handleFinish();
    }
  }, [isFinished]);

  const progressPercentage = Math.floor((answers.length / questions.length) * 100);

  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
      {/* <h2 className="text-xl font-bold mb-4">{getHeading()}</h2> */}
      <div>
        {!isFinished && (
          <div className="w-full bg-yellow-300 rounded-full h-4 mb-4 relative">
            <div
              className={`h-4 rounded-full ${isFinishing ? 'bg-gray-500' : 'bg-blue-500'}`}
              style={{ width: `${progressPercentage}%` }}
            ></div>
            <div className="absolute inset-0 flex items-center justify-center text-black text-xs">
              {progressPercentage}%
            </div>
          </div>
        )}
        {isLoading ? (
          <div className="loader"></div>
        ) : isFinished ? (
          <>
            <h2 className="text-xl text-blue-500  flex items-center justify-center font-bold mb-4">You're almost there!</h2>
            <p className="mb-4 flex items-center justify-center">
               Enter your phone number to proceed.
            </p>
            <input
              type="text"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              className="border p-2 mb-4 w-full"
              placeholder="Enter your phone number"
              required
            />
            {showPhoneError && (
              <p className="text-red-500 text-sm mb-4">
                Phone number is required!
              </p>
            )}
            <button
              className="mt-4 w-full text-center p-2 bg-blue-500 text-white rounded hover:bg-blue-700"
              onClick={handleFinish}
              disabled={isFinishing}
            >
              {isFinishing ? 'Finishing...' : 'Check Your Personality'}
            </button>
          </>
        ) : (
          <>
            <h3 className="text-lg font-bold mb-4">{questions[currentQuestionIndex].question}</h3>
            <ul>
              {questions[currentQuestionIndex].answers.map((option, index) => (
                <li key={index}>
                  <button
                    className={`w-full text-left p-2 border-b hover:bg-gray-100 ${selectedAnswer === option ? 'font-bold' : ''}`}
                    onClick={() => handleAnswerSelect(option)}
                  >
                    {option}
                  </button>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default Quiz;
